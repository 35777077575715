<script>
    export let icon = ''
    export let size = ''
    export let alt = ''
</script>

<span class="material-icons notranslate {size}" translate="no"  title={alt}>{icon}</span>

<style>
    .small{
        font-size: 0.8rem;
    }
</style>