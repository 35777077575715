<script>
    export let icon = ''
    export let alt = ''
</script>

<span class="fab fa-{icon} small" translate="no"  title={alt}></span>

<style>
    .small{
        font-size: 2rem;
    }
</style>
